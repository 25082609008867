import React from 'react';
import { Button, Chip, Col, Colors, Content, Div, Label, Row, SearchInput, Skeleton, Spacer, Tooltip } from '@gower/react-ui/components';

export const HomeView = () => {

    return (
        <HomeTemplate />
    );

};

export const HomeTemplate = () => {


    return <>
        <Content
            start
            justifyCenter
            scroll
            padding={20}
        >

            <Col
                cols={12}
                maxWidth={1200}
                padding={30}
                paddingBottom={100}
                white
                boxShadow="box-shadow-1"
            >
                <Div block>
                    <Label p bold xxnormal marginX={10} marginY={30}>
                        POLITICA DE PRIVACIDAD
                    </Label>
                    <Label p bold normal margin={10}>
                        1. Declaración General:
                    </Label>
                    <Label p normal margin={10} style={{ textAlign: "justify" }}>
                        SAMI se compromete a adoptar una Política de Privacidad que proteja la información personal
                        obtenida de los usuarios a través de su sitio web, actuando de buena fe, basado en principios de
                        confianza, transparencia, y brindando seguridad, con sujeción a la legislación vigente.-
                        Mediante el sitio web SAMI puede recopilar cierta información sobre la que el usuario debe entender
                        plenamente nuestra política con respecto al uso de la información que recogemos.
                        SAMI se reserva el derecho de revisar, actualizar o modificar la Política de Privacidad que a
                        continuación se detalla a fin de adaptarla a nuevas exigencias legislativas, jurisprudenciales y/o
                        técnicas, como así también a todos aquellos requerimientos que le permitan mejorar el servicio.-
                        Se debe tener presente que el sitio web de SAMI puede contener enlaces a otros sitios, sobre los
                        cuales SAMI no se responsabiliza. Recomendamos a nuestros usuarios que lean las declaraciones
                        de confidencialidad de cada uno de los sitios web en los que rigen sus propias políticas de
                        confidencialidad.
                        Nuestro sistema podría obtener información automáticamente acerca de su conducta cuando navega
                        por el sitio web, a través de un "cookie". Un “cookie” es un pequeño archivo de datos que puede llegar
                        desde el sitio web de SAMI al navegar del usuario y luego puede quedar almacenada en su disco
                        rígido por un período de tiempo limitado. El uso de “cookie” permite que SAMI pueda personalizar
                        ciertos servicios y comunicaciones. Por esta razón en ocasiones el sitio web de SAMI , y todos los
                        relacionados, puede enviar “cookies” para que los usuarios puedan personalizar algunas opciones,
                        pero nunca utilizará “cookies” a fin de dejar registros de los contenidos elegidos por el usuario que
                        navegue por el sitio web. En caso de requerirlo, el usuario puede modificar su programa de
                        navegación para que lance una advertencia en el momento que ingresa un “cookie” o bien
                        deshabilitarlos. Para ello deberá consultar el menú de Ayuda de su navegador.
                    </Label>
                    <Label p normal bold margin={10}>
                        2. Datos Personales:
                    </Label>
                    <Label p normal margin={10} style={{ textAlign: "justify" }}>
                        SAMI recopila cierta información personal de los usuarios para poder brindarles los servicios que
                        ofrece a través del sitio web, realizar consultas y tramites on-line. Para poder suministrar
                        información, dichos usuarios necesitan estar previamente registrados. SAMI no solicita información
                        que no sea compatible con la finalidad de sus actividades. La información personal que SAMI obtiene
                        de los usuarios es de carácter estrictamente confidencial. SAMI limita el acceso a sus datos a
                        empleados que necesiten contar con dicha información para proveerle productos o servicios o a
                        efectos de poder realizar su trabajo. SAMI utiliza esta información internamente, para poder
                        procesar su solicitud. SAMI no revelará ni compartirá ninguna información que pueda identificar a
                        las personas con ninguna otra empresa u organización ajena sin el consentimiento expreso del
                        afiliado, o cualquier otra persona que proporcione la información. SAMI no permite que terceros
                        ajenos a la institución accedan a información personal del usuario, salvo las excepciones del
                        siguiente apartado. SAMI puede utilizar la información para enviar al usuario información sobre
                        nuestra institución. Los usuarios podrán optar, si así lo desean, por no recibir envíos en el futuro,
                        solicitándolo expresamente por carta o por mail a las direcciones que se le indiquen. El titular se
                        responsabiliza por la exactitud de los datos proporcionados.
                    </Label>
                    <Label p normal bold margin={10}>
                        3. Excepciones:
                    </Label>
                    <Label p normal margin={10} style={{ textAlign: "justify" }}>
                        SAMI podrá brindar cierta información personal de los usuarios sólo en caso de: a) cumplir una
                        exigencia legal tal como una orden de allanamiento, una citación judicial, una orden judicial; b)
                        cumplir un requerimiento de una autoridad gubernamental o reguladora;
                    </Label>
                    <Label p normal bold margin={10}>
                        4. Privacidad de los niños:
                    </Label>
                    <Label p normal margin={10} style={{ textAlign: "justify" }}>
                        Debido a que los menores de edad pueden no comprender debidamente la presente Política de
                        Privacidad y sus implicancias, ni decidir válidamente sobre las opciones disponibles para nuestros
                        usuarios, instamos a todos los padres o representantes o adultos bajo cuya supervisión, los menores
                        accedan al sitio web a participar activa y cuidadosamente en las actividades que el menor realice
                        en Internet, a los servicios que utilicen dichos menores, a la información a la que estos accedan y
                        comuniquen, ya sea cuando dichos menores visiten el sitio web cualquier otro sitio de terceros.
                    </Label>
                    <Label p normal bold margin={10}>
                        5. Derecho de acceso, rectificación y supresión de los datos registrados
                    </Label>
                    <Label p normal margin={10} style={{ textAlign: "justify" }}>
                        SAMI reconoce al Titular de los Datos Personales, previa acreditación de su identidad, el derecho a
                        solicitar y a obtener información sobre sus datos personales incluidos en sus registros, dentro de
                        los diez días hábiles desde la solicitud, de conformidad con lo establecido en la Ley 25.326 de
                        protección de Datos Personales. SAMI garantiza también al Titular de los Datos Personales el
                        derecho a obtener la rectificación, actualización y, cuando corresponda, la supresión de los datos
                        personales de los que sea titular, que estén incluidos en su banco de datos y garantiza la
                        rectificación, supresión o actualización de los mismos en el plazo máximo de cinco días hábiles de
                        recibido el reclamo del titular de los datos. En cumplimiento de la disposición 10/2008 de la Dirección
                        Nacional de Protección de Datos Personales se hace saber que: El Titular de los Datos Personales
                        tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita y en intervalos no
                        inferiores a seis meses, salvo que acredite un interés legítimo al efecto, conforme con lo establecido
                        en el artículo 14, inciso 3 de la Ley Nº 25.326. Los derechos de acceso y rectificación de datos, se
                        deberán ejercer a través de correo electrónico dirigido a comunicacion@centromedicomdp.org.ar a
                        requerimiento del titular de los datos sin costo alguno. Para ello se deberá remitir un correo
                        electrónico a la dirección mencionada, con el asunto “Informar”, “Rectificar”, “Suprimir” y/o
                        “Actualizar” según corresponda, conjuntamente con el objeto del requerimiento. SAMI podrá denegar
                        el acceso, rectificación o la supresión de los datos personales registrados únicamente por las causas
                        previstas en la Ley de protección de datos personales.
                    </Label>

                    <Label p bold normal textCenter margin={50}>
                        Ante cualquier duda acerca de la Política de Privacidad de SAMI, Ud. puede ponerse en contacto por
                        correo electrónico a comunicacion@centromedicomdp.org.ar. Estamos a su disposición para
                        brindarle toda la información que necesite.-
                    </Label>
                </Div>
            </Col>

        </Content>
    </>;
}; 