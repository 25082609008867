import { Color, Colors } from '@gower/react-ui';
import React from "react";


export type AppLogoProps = {
  color?: Color;
  dark?: boolean;
  size?: number;
  v1?: boolean;
  v2?: boolean;
};

export const AppLogo: React.FunctionComponent<AppLogoProps> = ( {
  dark, v1, v2,
  ...props
} ) =>
{

  if ( typeof v1 === 'undefined' && typeof v2 === 'undefined' )
    v2 = true;

  let width = 30;
  let height = 30;
  if ( typeof props.size !== 'undefined' )
  {
    width = props.size;
    height = props.size;
  }

  return (
    <>
      <Iso height={height} width={width} background={dark ? `var(--white)` : `var(--blue-9)`} color={Colors[props.color]} />
    </>
  );


};

function Iso ( { ...props } )
{
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 473 248.91"
      dangerouslySetInnerHTML={{
        __html:
          `
          <path fill="${props.color}" d="M83.199,134.594c0,5.985-1.231,11.359-3.684,16.115c-2.455,4.76-5.983,8.786-10.586,12.089
          c-4.604,3.3-10.166,5.868-16.687,7.711c-6.524,1.842-13.771,2.759-21.751,2.759c-3.989,0-8.017-0.191-12.083-0.577
          c-4.063-0.381-7.938-0.803-11.621-1.266l0.69-28.545c3.525,1.009,7.36,1.832,11.505,2.481c4.138,0.648,8.279,0.973,12.425,0.973
          c4.445,0,7.973-0.839,10.583-2.526c2.607-1.678,3.915-3.825,3.915-6.43c0-2.753-1.037-4.936-3.109-6.541
          c-2.072-1.612-4.684-3.063-7.829-4.365c-3.146-1.303-6.523-2.641-10.128-4.02c-3.609-1.377-6.985-3.292-10.13-5.742
          c-3.147-2.447-5.756-5.664-7.828-9.646c-2.072-3.978-3.106-9.11-3.106-15.386c0-5.204,1.035-10.104,3.106-14.697
          c2.072-4.592,5.216-8.608,9.439-12.055c4.22-3.446,9.515-6.202,15.887-8.271c6.366-2.064,13.773-3.102,22.216-3.102
          c3.223,0,6.561,0.196,10.014,0.577c3.453,0.383,6.791,0.805,10.016,1.266l-0.692,27.397c-3.05-0.922-6.287-1.614-9.715-2.075
          c-3.433-0.458-6.9-0.689-10.404-0.689c-4.422,0-7.698,0.805-9.832,2.416c-2.136,1.614-3.201,3.725-3.201,6.333
          c0,2.456,1.068,4.415,3.207,5.87c2.136,1.46,4.769,2.917,7.898,4.373c3.129,1.462,6.526,2.994,10.191,4.606
          c3.662,1.612,7.062,3.721,10.191,6.331c3.129,2.612,5.762,5.87,7.9,9.785C82.13,123.658,83.199,128.607,83.199,134.594z"/>
              <path fill="${props.color}" d="M159.891,170.571c0-2.764,0.037-5.601,0.116-8.523c0.075-2.914,0.345-5.826,0.806-8.745h-0.229
          c-2.783,6.037-7.334,10.875-13.664,14.509c-6.33,3.64-13.507,5.456-21.537,5.456c-11.05,0-19.378-2.914-24.979-8.745
          c-5.604-5.831-8.402-13.356-8.402-22.559c0-7.216,1.654-13.506,4.966-18.882c3.31-5.372,7.852-9.821,13.629-13.354
          c5.777-3.527,12.631-6.176,20.567-7.941c7.932-1.764,16.442-2.649,25.529-2.649v-4.375c0-4.753-1.505-8.287-4.511-10.59
          c-3.008-2.301-8.362-3.454-16.066-3.454c-5.085,0-10.752,0.731-16.996,2.188c-6.246,1.462-11.753,3.495-16.53,6.103l-2.764-29.009
          c5.832-1.843,12.394-3.412,19.685-4.72c7.288-1.302,14.54-1.956,21.756-1.956c9.821,0,17.994,0.921,24.518,2.758
          c6.523,1.837,11.701,4.483,15.541,7.929c3.834,3.448,6.521,7.701,8.057,12.754c1.531,5.059,2.304,10.878,2.304,17.469v49.185
          c0,4.138,0.075,8.58,0.229,13.335c0.152,4.756,0.381,9.363,0.689,13.814H159.891z M156.694,121.472
          c-6.339,0-11.557,0.418-15.654,1.255c-4.1,0.836-7.306,1.941-9.625,3.31c-2.318,1.366-3.942,2.962-4.868,4.789
          c-0.929,1.822-1.392,3.726-1.392,5.702c0,2.888,1.003,5.245,3.013,7.072c2.007,1.823,4.872,2.733,8.583,2.733
          c2.936,0,5.642-0.606,8.117-1.822c2.473-1.22,4.56-2.815,6.261-4.792c1.698-1.976,3.053-4.292,4.059-6.958
          c1.003-2.661,1.507-5.358,1.507-8.096V121.472z"/>
              <path fill="${props.color}" d="M345.23,170.735V98.718c0-4.923-0.73-8.77-2.187-11.541c-1.462-2.77-4.185-4.154-8.174-4.154
          c-2.615,0-5.261,0.81-7.942,2.424c-2.691,1.619-5.105,3.888-7.252,6.809c-2.15,2.926-3.881,6.349-5.177,10.274
          c-1.309,3.924-1.961,8.271-1.961,13.039v55.166h-34.532V98.718c0-4.616-0.69-8.386-2.074-11.31
          c-1.38-2.921-4.144-4.385-8.287-4.385c-2.764,0-5.523,0.849-8.292,2.54c-2.759,1.693-5.177,4.004-7.247,6.925
          c-2.074,2.924-3.767,6.349-5.064,10.273c-1.307,3.925-1.956,8.194-1.956,12.808v55.166H210.09V56.156h32.922
          c0,2.151-0.115,4.948-0.345,8.402c-0.231,3.454-0.576,6.717-1.037,9.785h0.229c3.532-6.396,8.478-11.421,14.85-15.076
          c6.372-3.654,13.619-5.481,21.756-5.481c8.75,0,15.693,1.907,20.841,5.71c5.136,3.807,8.627,8.758,10.469,14.847
          c3.058-5.634,7.648-10.466,13.769-14.504c6.119-4.036,13.541-6.053,22.261-6.053c11.169,0,19.691,3.147,25.585,9.44
          c5.894,6.294,8.838,15.963,8.838,29.005v78.504H345.23z"/>
              <path fill="${props.color}" d="M436.261,23.627c0,2.761-0.571,5.409-1.725,7.942c-1.152,2.533-2.687,4.758-4.606,6.676
          c-1.919,1.922-4.226,3.417-6.907,4.49c-2.687,1.076-5.492,1.611-8.404,1.611c-2.914,0-5.678-0.535-8.287-1.611
          c-2.609-1.074-4.875-2.533-6.794-4.375c-1.915-1.842-3.448-4.029-4.602-6.562c-1.153-2.532-1.729-5.255-1.729-8.171
          c0-2.916,0.576-5.642,1.729-8.173c1.153-2.533,2.687-4.72,4.602-6.562c1.919-1.843,4.185-3.3,6.794-4.375
          c2.609-1.071,5.373-1.611,8.287-1.611c2.912,0,5.718,0.54,8.404,1.611c2.682,1.076,4.988,2.533,6.907,4.375
          c1.92,1.842,3.454,4.029,4.606,6.562C435.689,17.985,436.261,20.711,436.261,23.627z M397.123,170.735V56.318h34.995v114.417
          H397.123z"/>
              <path fill="${props.color}" d="M469.228,192.749c0-0.52-4.365-1.672-8.267-1.853c-0.072-0.005-0.113-0.016-0.185-0.016
          c-0.109-0.005-0.325-0.011-0.49-0.016c-0.02,0-0.041-0.004-0.057-0.004c-0.004,0-0.01,0-0.01,0
          c-10.401-0.299-122.478,0.092-211.252,3.973c-90.477,3.953-154.498,3.078-156.965,3.078c-2.468,0-61.283,0.232-61.942,3.5
          c0,0-5.783,27.104-3.196,32.582c2.586,5.486,8.22,10.206,17.966,11.725c9.746,1.523,35.784-3.5,35.784-3.5
          s75.979-12.333,79.179-12.79c3.198-0.459,103.667-10.876,131.337-13.29c25.519-2.229,124.392-10.063,165.461-20.31l0.015,0.01
          C456.606,195.837,469.228,193.299,469.228,192.749z"/>
      `}}>

    </svg>
  );
}


export default AppLogo;
